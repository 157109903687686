
import { getAlarmList } from '@/api/pushAlarm';
import Pagination from '@/components/board/pagination.vue';
import { UserModule } from '@/store/user';
import {
  Component, Vue,
} from 'vue-property-decorator';

@Component({
  name: 'AlarmIndex',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  mounted() {
    if (this.isLogined) {
      this.getAlarmList();
    } else {
      this.$router.push('/app/main');
    }
  }

  get isLogined() {
    return UserModule.token;
  }

  private alarmList = [];

  private totalElements = 0;

  private totalPages = 0;

  private listQuery = {
    page: 0,
    size: 10,
  };

  private getAlarmList() {
    getAlarmList(this.listQuery).then((res) => {
      this.alarmList = res.data.content;
      this.totalElements = res.data.totalElements;
      this.totalPages = res.data.totalPages;
    });
  }

  private handleChangePage(page: number) {
    this.listQuery.page = page;
    this.getAlarmList();
  }

  private getBoardName(title: string) {
    const index = title.indexOf(']');
    return title.substr(0, index + 1);
  }

  private getTitle(title: string) {
    const index = title.indexOf(']');
    return title.substr(index + 1);
  }
}
